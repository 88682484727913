import SimpleLightbox from "simplelightbox";
import 'simplelightbox/dist/simple-lightbox.css'

const galleryInit = document.querySelector('#galleryInit');
const galleryStart = document.querySelector('#galleryStart');

galleryInit.addEventListener('click', () => {
    galleryStart.click();
});

var lightbox = new SimpleLightbox('#commercialGallery a',);
