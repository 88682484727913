import './styles/main.scss';

import initMap from './js/map';
import initContactForm from './js/initContactForm';
import initTenantsBG from './js/initTenantsBG';
import './js/initCommercialGallery';
import initParallax from './js/initParallax';

const nav = document.querySelector('#nav');
const navTrigger = document.querySelector('.heroSwiper');

function triggerNavSticky() {
    if (window.scrollY >= navTrigger.getBoundingClientRect().top) {
        nav.classList.add('sticky');
    } else {
        nav.classList.remove('sticky');
    }
}

const navToggle = document.querySelector('#navToggle');
const navMobileContent = document.querySelector('.nav__items');

navToggle.addEventListener('click', () => {
    navToggle.classList.toggle('is-active');
    navMobileContent.classList.toggle('active');
});

const navLinks = document.querySelectorAll('.nav__link');
navLinks.forEach(link => {
    link.addEventListener('click', () => {
        navMobileContent.classList.contains('active') && navMobileContent.classList.remove('active');
    })
})


window.addEventListener('load', () => {
    triggerNavSticky();
    initContactForm();
    initParallax();
    initMap();
    initTenantsBG();
});

const links = document.querySelectorAll('.nav__link');
const sections = document.querySelectorAll('.navTrigger');

function triggerNavCurrent() {
    let index = sections.length;

    while(--index && window.scrollY  + 500 < sections[index].offsetTop) {}
    
    links.forEach((link) => link.classList.remove('active'));
    links[index].classList.add('active');

    if(window.scrollY < 500 ) links.forEach((link) => link.classList.remove('active'));

}


const items = document.querySelectorAll('.gallery-item');
items.forEach(item => console.log(item));

window.addEventListener('scroll', triggerNavSticky);
window.addEventListener('resize', () => {
    if (window.outerWidth <= 1200) {
        navMobileContent.classList.contains('active') && navMobileContent.classList.remove('active');
        navToggle.classList.remove('is-active');
    }
})
window.addEventListener('scroll', triggerNavCurrent);