function initTenantsBG() {
  let bg = document.querySelector("#tenants");
  let currentBgIndex = bg.getAttribute("data-testimonial-bg");

  function switchBG() {
      if (currentBgIndex < 4) {
        currentBgIndex++;
      } else {
        currentBgIndex = 0;
      }

      bg.setAttribute("data-testimonial-bg", currentBgIndex);
    }

    setInterval(() => {
        switchBG();
    }, 5000)
}

export default initTenantsBG;