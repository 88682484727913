import 'regenerator-runtime/runtime';

const submitBtn = document.querySelector('#submitBtn');
const contactResponse = document.querySelector('#contactResponse');
const formInputs = document.querySelectorAll('.form__input');
let error = false;

function initContactForm() {
    submitBtn.addEventListener('click', handleFormSubmit);

    formInputs.forEach(input => {
        input.addEventListener('input', () => {
            error = false;
            contactResponse.classList.contains('active') && contactResponse.classList.remove('active');
            contactResponse.textContent = "";
        });
    })
}

function handleFormSubmit() {
    const formData = {
        userName: document.querySelector(".form__input[name='name']").value,
        userEmail: document.querySelector(".form__input[name='email']").value,
        userPhone: document.querySelector(".form__input[name='phone']").value,
        userMessage: document.querySelector(".form__input[name='message']").value,
    }

    if (formData.name === '' || formData.email === '') {
        error = true;
    }

    if (error == true) {
        contactResponse.textContent = 'Please fill out all required fields!';
        contactResponse.classList.add('active');
    } else {
        postForm(formData);
    }	 
}

function postForm(data) {
    const url = 'http://192.99.46.183/~saumuregroup/php/contact_me.php';

    $.post(url, data, function (data) {
        contactResponse.textContent = 'Thank you! We will be in touch shortly.'
        contactResponse.classList.add('active');

        setTimeout(() => {
            contactResponse.classList.remove('active');
            contactResponse.textContent = "";

            formInputs.forEach(input => input.value = '');
        }, 3000);
    }).fail(err => {
        contactResponse.textContent = 'Something went wrong...'
        contactResponse.classList.add('active');
        console.log(err);
    });
}

export default initContactForm;