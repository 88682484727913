function parallaxScroll(val) {
    const item = document.querySelector(val);
    const itemCoords = item.getBoundingClientRect();

  
    const start = itemCoords.top - itemCoords.height >= 0;
    const end = itemCoords.bottom <= 0;

    if (start === false && end === false) {
        let percent = Math.abs(itemCoords.top - itemCoords.height) / 10;

        if (percent < 0) percent = 0;
        if (percent > 100) percent = 100;

        item.style.backgroundPosition = `center ${percent}%`;
    }
}

function initParallax() {
    window.addEventListener('scroll', () => parallaxScroll('.map__copy'));
    window.addEventListener('scroll', () => parallaxScroll('.commercial__header'));
}

export default initParallax;