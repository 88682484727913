import beckwith from '../assets/images/marker-beckwith-square.svg'; 
import carleton from '../assets/images/marker-carleton-place.svg';
import settlers from '../assets/images/marker-settlers-square.svg'; 
import trailside from '../assets/images/marker-trail-side-square.svg'; 
import smithsFalls from '../assets/images/marker-smiths-falls.svg'; 
import collegiate from '../assets/images/marker-collegiate-court.svg'; 

var mapItems = [
        {'title': 'Settlers Square', 'lat': 44.8832998, 'lang': -76.0211809, 'image': settlers, anchor: [-100,0],   },
        {'title': 'Trailside Square', 'lat': 44.8881416, 'lang': -76.0304189, 'image': trailside, anchor: [0,0] },
        {'title': 'Smiths Falls', 'lat': 44.9213493, 'lang': -76.0397784, 'image': smithsFalls, anchor: [75, 75] },
        {'title': 'Carleton Place', 'lat': 45.1384672, 'lang': -76.1563125, 'image': carleton, anchor: [0,100] },
        {'title': 'Beckwith Square', 'lat': 45.1384607, 'lang': -76.1445664, 'image': beckwith, anchor: [0,0] },
        {'title': 'Collegiate Court', 'lat': 44.908934, 'lang': -76.0281057, 'image': collegiate, anchor: [0,100] },
];

var openWindow;

function initMap() {
		var mapCentre = {lat: 45.0156475, lng: -76.0320346};
        var map = new google.maps.Map(document.getElementById('googleMap'), {
          zoom: 10,
			styles: [
				{
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#212121"
					}
				  ]
				},
				{
				  "elementType": "labels",
				  "stylers": [
					{
					  "color": "#ffffff"
					}
				  ]
				},
				{
				  "elementType": "labels.icon",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "elementType": "labels.text",
				  "stylers": [
					{
					  "color": "#ffffff"
					},
					{
					  "visibility": "on"
					}
				  ]
				},
				{
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#ffffff"
					},
					{
					  "visibility": "on"
					}
				  ]
				},
				{
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "administrative",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#3a4855"
					}
				  ]
				},
				{
				  "featureType": "administrative.country",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#9e9e9e"
					}
				  ]
				},
				{
				  "featureType": "administrative.land_parcel",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "administrative.locality",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#bdbdbd"
					}
				  ]
				},
				{
				  "featureType": "administrative.neighborhood",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "landscape",
				  "stylers": [
					{
					  "color": "#3a4855"
					}
				  ]
				},
				{
				  "featureType": "poi",
				  "elementType": "labels.text",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "poi",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#757575"
					}
				  ]
				},
				{
				  "featureType": "poi.business",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#181818"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#616161"
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "color": "#1b1b1b"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "stylers": [
					{
					  "color": "#ffffff"
					},
					{
					  "visibility": "on"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "geometry.fill",
				  "stylers": [
					{
					  "color": "#ffffff"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "labels",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "labels.icon",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "road",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#8a8a8a"
					}
				  ]
				},
				{
				  "featureType": "road.arterial",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#373737"
					}
				  ]
				},
				{
				  "featureType": "road.arterial",
				  "elementType": "geometry.fill",
				  "stylers": [
					{
					  "color": "#e2e2e2"
					},
					{
					  "saturation": -45
					}
				  ]
				},
				{
				  "featureType": "road.arterial",
				  "elementType": "labels",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#3c3c3c"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "geometry.fill",
				  "stylers": [
					{
					  "color": "#d9091f"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "labels",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "road.highway.controlled_access",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#4e4e4e"
					}
				  ]
				},
				{
				  "featureType": "road.local",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "road.local",
				  "elementType": "geometry.fill",
				  "stylers": [
					{
					  "color": "#ffffff"
					}
				  ]
				},
				{
				  "featureType": "road.local",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#616161"
					}
				  ]
				},
				{
				  "featureType": "transit",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "transit",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#757575"
					}
				  ]
				},
				{
				  "featureType": "water",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "water",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#000000"
					}
				  ]
				},
				{
				  "featureType": "water",
				  "elementType": "labels.text",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "featureType": "water",
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#3d3d3d"
					}
				  ]
				}
			  ],
          center: mapCentre
        });
       
		for (let i = 0; i < mapItems.length; i++) {
			let icon = {
				url: mapItems[i].image,
				scaledSize: new google.maps.Size(100, 100),
				origin: new google.maps.Point(0,0), // origin
				anchor: new google.maps.Point(mapItems[i].anchor[0], mapItems[i].anchor[1]) // anchor

			}
			new google.maps.Marker({
				position:{ lat: mapItems[i].lat, lng: mapItems[i].lang },
				map: map,
				icon: icon,			
			  });
		}
      }

function addMarker(location, map, mapNumber) {
	

}

export default initMap;